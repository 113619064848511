.loading {
    z-index: 999999;
    margin: 0 auto;
    bottom: 0;
    right: 0;
    background-color: rgba(255, 255, 255, 1);
    display: flex;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    justify-content: center;
  }
  :root {
    --primary-color: #1B9DB3;
    --secondary-color: #F07F1B;
    --nav-color: #2E3639;
    --nav-color-hover: #f07f1b;
    --nav-color-active: #1B9DB3;
    --footer-header: #fff;
    --footer-paragraph: #c2c2c2;
}
  @keyframes ssc-loading {
    from {
        transform: translateX(-100%);
    }
    to {
        transform: translateX(100%);
    }
}
body .swiper-button-next.swiper-button-disabled,body .swiper-button-prev.swiper-button-disabled {
    opacity: 0;
}
.secdivide {
    padding: 20px;
    border-top: 1px solid #E6E6E6;
}
.secdivide #addresscontinuebtn {
    margin: 0;
}
.panel.griderror {
    border: 1px solid #ff0000;
}
.panelerror {
    padding: 0 20px;
}
.ssc {
    cursor: progress;
    user-select: none;
}
.ssc .mb {
        margin-bottom: 16px;
    }

    .ssc .mt {
        margin-top: 16px;
    }

    .ssc .mr {
        margin-right: 16px;
    }

    .ssc .ml {
        margin-left: 16px;
    }

    .ssc .mbs {
        margin-bottom: 8px;
    }

    .ssc .mts {
        margin-top: 8px;
    }

    .ssc .mrs {
        margin-right: 8px;
    }

    .ssc .mls {
        margin-left: 8px;
    }

    .ssc .w-10 {
        width: 10%;
    }

    .ssc .w-20 {
        width: 20%;
    }

    .ssc .w-30 {
        width: 30%;
    }

    .ssc .w-40 {
        width: 40%;
    }

    .ssc .w-50 {
        width: 50%;
    }

    .ssc .w-60 {
        width: 60%;
    }

    .ssc .w-70 {
        width: 70%;
    }

    .ssc .w-80 {
        width: 80%;
    }

    .ssc .w-90 {
        width: 90%;
    }

    .ssc .w-100 {
        width: 100%;
    }

    .ssc .flex {
        display: flex;
    }

    .ssc .inline-flex {
        display: inline-flex;
    }

    .ssc .flex-column {
        flex-direction: column;
    }

    .ssc .flex-column-reverse {
        flex-direction: column-reverse;
    }

    .ssc .flex-row {
        flex-direction: row;
    }

    .ssc .flex-row-reverse {
        flex-direction: row-reverse;
    }

    .ssc .align-center {
        align-items: center;
    }

    .ssc .align-start {
        align-items: flex-start;
    }

    .ssc .align-end {
        align-items: flex-end;
    }

    .ssc .align-stretch {
        align-items: stretch;
    }

    .ssc .justify-start {
        justify-content: start;
    }

    .ssc .justify-center {
        justify-content: center;
    }

    .ssc .justify-end {
        justify-content: end;
    }

    .ssc .justify-between {
        justify-content: space-between;
    }

    .ssc .justify-around {
        justify-content: space-around;
    }
    .ssc-wrapper {
        padding: 16px;
    }
    
    /* Figures, Objects */
    .ssc-card {
        display: none;
        width: 100%;
        height: 100%;
        border-radius: 5px;
        background-color: #fff;
    }
    .ssc-base {
        display: block;
        background-color: rgba(0, 0, 0, 0.17);
        position: relative;
        overflow: hidden;
    }
    .ssc-circle {
        border-radius: 50%;
        width: 50px;
        height: 50px;        
    }
    .ssc-base::after { 
        content: "";
        animation: ssc-loading 1.3s infinite;
        height: 100%;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        transform: translateX(-100%);
        z-index: 1;
        background: linear-gradient(90deg, transparent, hsla(0, 0%, 100%, 0.3), transparent);
    }
    .ssc-hr {
        width: 100%;
        height: 2px;       
    }
    
    .ssc-line {
        border-radius: 15px;
        width: 100%;
        height: 12px;
    }
    
    .ssc-head-line {
        border-radius: 15px;
        width: 100%;
        height: 24px;
    }
    
    .ssc-square {
        width: 100%;
        height: 150px;
    }
    .ssc-card-container {
        position: fixed;
        z-index: 999;
    }
    .ssc-card-container {
        position: fixed;
        z-index: 1;
        width: 100%;
        height: 100%;
        background: #fff;
      }


      .ttslider img {
        width: 100%;
        cursor: pointer;
    }
    .tt-grid {
        margin: 20px 0;
    }
    
    .tt-sidebar {
        margin: 20px 0;
        background: #000;
        padding: 10px;
    }
    
    .tt-img {
        text-align: center;
        margin: 0 0 20px;
    }
    
    .tt-grid p {
        text-align: justify;
    }
    
    .tt-grid p:not(:last-child) {
        margin-bottom: 20px;
    }
    
    .tt-sidebar a {
        color: #fff;
        padding: 10px 20px;
        display: block;
        transition: all 0.5s;
    }
    
    .tt-sidebar a.active {
        background: #fff;
        color: #000;
        border-radius: 2px;
    }
    .tt-sidebar a:hover {
        background: #dedee0;
        color: #000; 
    }
    .tt-grid h4 {
        font-weight: bold;
        color: red;
        margin: 20px 0;
    }
    
    .tt-grid li {
        list-style: disc;
    }
    
    .tt-grid ul {
        padding: 0 0 0 20px;
    }
    
    .tt-grid li:not(:last-child) {
        margin-bottom: 5px;
    }
.ttslider .swiper-button-next::after,
.ttslider .swiper-button-prev::after {
    color: #fff;
    border: 0;
    font-size: 46px !important;
}
.ttslider a {
    display: block;
}
.urlplaceholder {
    flex: 1 0 auto;
    position: relative;
}

.urlplaceholder:after {
    content: "https://";
    position: absolute;
    top: 7px;
    left: 7px;
}

.urlplaceholder .form-control {
    padding-left: 70px;
}
.acc-payments {
    margin: 15px 0 0;
}
  .edit-section .swiper-button-next::after,.edit-section .swiper-button-prev::after 
  { 
    background: #192335;
    border-radius: 0;
    height: 100%;
    position: absolute;
    color: #fff;
  }
  .nav-item.nav-option {
    position: relative;
  }
  .tab-close {
    position: absolute;
    right: 10px;
    top: 9px;
    background: #ffff;
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 3px;
    cursor: pointer;
  }
  .img-bg {
    border: 2px solid #fff;
    box-shadow: 0px 0px 2px rgba(0,0,0,0.2);
    padding: 10px;
    border-radius: 5px;
    text-align: center;
    margin: 20px 0;
}
.btncolorpicker > span {
  height: 100%;
}
.img-bg img {
  max-width: 100%;
  height: auto;
  max-height: 100%;
}
.img-option img {
  height: 40px;
  border: 4px solid #fff;
  border-radius: 2px;
  box-shadow: 1px 4px 4px rgba(0,0,0,0.5);
}

.form-contact,.contgrid {
    border: 1px solid #c9c9c9;
    border-radius: 10px;
    padding: 20px;
    margin: 0 0 25px;
  }
  .contgrid i {
    color: var(--primary-color);
  }
  .contgrid a {
    color: #6B7385;
  }



  .trust-section2 li {
    margin-bottom: 20px;
    padding-left: 30px;
    color: #6d7a80;
    background: url(../img/icons/tick.svg) no-repeat;
    background-position: left center;
  }
  .personal-info .fa-copy{
    margin-left: 10px;
    cursor: pointer;
  }
  .pro-det{
    min-width: 30%;
    width: auto;
  }
  .offer-section-4 {
    padding: 0 0 70px;
}
  .offer-section-4grid {
    background: var(--primary-color) url(../img/offer-banner-bg.png) no-repeat;
    background-position: top;
    padding: 53px 20px;
    margin: 40px 0 0;
  }
  .offer-section-4grid .swiper-button-next::after,.offer-section-4grid .swiper-button-prev::after { 
    color: #fff;
  }
  .offer-card{
    background: rgba(255,255,255,0.3);
    padding: 10px;
    border-radius: 5px;
  }
  .offer-wrap-price {
    display: flex;
    font-size: 26px;
    font-weight: 600;
    align-items: center;
  }
  .offer-wrap-price i{
    font-size: 20px;
    cursor: pointer;
  }
  .offer-wrap-left {
    color: #fff;
    font-size: 40px;
  }
  .offer-wrap-right img {
    margin-top: 0;
    background: #fff;
    border: 2px solid #fff;
    border-radius: 50%;
    padding: 3px;
    width: 100%;
    height: 100%;
  }
  .offer-wrap-right {
    display: none;
    width: 50px;
    height: 50px;
    justify-content: center;
  }
  .offermodal-body {
    border: none;
    border-radius: 0;
    border-radius: 30px;
    color: #333;
    padding: 30px;
    text-align: center;
}
.offermodal-body h3 {
    font-size: 36px;
    font-weight: 700;
    text-transform: uppercase;
    margin: 0;
    padding: 10px 0 15px;
}
.offermodal-body h4 {
    font-size: 20px;
    font-weight: 400;
    margin: 0;
    padding: 0;
}
.offermodal-body h4.promo {
    margin-top: 30px;
}
.offermodal-body h4 {
    font-size: 20px;
    font-weight: 400;
    margin: 0;
    padding: 0;
}
.offermodal-body p {
    font-size: 16px;
    margin: 0;
}
.offercode {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 36px;
    letter-spacing: 5px;
    display: flex;
    margin: 10px 0;
    position: relative;
    height: 70px;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 2px 4px rgba(0,0,0,0.4);
    border-radius: 4px;
    background: var(--primary-color);
    color: #fff !important;
}
.offer-modal-body .close-btn {
    color: #333;
  }
.offercode i {
    cursor: pointer;
}
.section-shop-info {
    margin: 40px 0 0;
}
.section-shop-hr {
    padding-bottom: 30px;
    border-bottom: 1px solid #cdcdcd;
}
.sec-shop-details h2 {
    margin: 0 0 10px;
    font-weight: 600;
}
.sec-shop-adrs {
    font-size: 16px;
}
.sec-shop-adrs i {
    color: var(--primary-color);
}
.sec-shop-details li:not(:last-child) {
    margin: 0 0 7px;
}
.sec-shop-details li {
    font-size: 16px;
}
.sec-shop-img img {
    border-radius: 5px;
}
.sec-shop-details li i {
    color: var(--primary-color);
}
.es-main-container a{
    display: none !important;
}

.maintenance-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    min-height: 100vh;
    max-height: 100vh;
    text-align: center;
    padding: 20px;
}
.maintenance-img img {
    max-height: 50vh;
  }
.maintenance {
    min-height: 100vh;
}
.maintenance-card h2 {
    margin: 30px 0;
    font-weight: 700;
}
.maintenance-card h4 {
    margin: 0 0 25px;
    font-weight: 700;
}
.home-shop-h2 {
    font-weight: 600;
    margin-bottom: 10px;
    font-size: 26px;
}
.shop-hdrspan {
    font-size: 28px;
}
.home-shop-hdr {
    display: flex;
    align-items: center;
    margin: 0 0 10px;
    justify-content: space-between;
  }
  .shop-grid {
    box-shadow: 0px 4px 10px 0px #0000000D;
    border-radius: 10px;
    margin-bottom: 25px;
    border: 1px solid #E6E6E6;
    overflow: hidden;
    background: #fff;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.shop-img img{
    height: 110px;
}
.shop-details-adrs {
    -webkit-line-clamp: 2;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    min-height: 40px;
    margin: 0 0 6px;
}
.shop-details h3 {
    font-size: 18px;
    font-weight: 600;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.shop-details {
    padding: 20px;
    height: 100%;
    display: flex;
    flex-direction: column;
}
.shop-details h3 {
    font-size: 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.shop-details ul {
    padding: 0;
}

.shop-details li {
    list-style: none;
    margin: 0 0 10px;
    display: flex;
    justify-content: space-between;
}

.shop-price {
    font-weight: 600;
}
.shop-grid {
    border-radius: 0;
}

.shop-modal .modal-content {
    background: #2b2f3e;
}
.shop-modal .modal-header {
    border: 0;
}
.shop-modal .btn-close {
    filter: brightness(0) invert(1);
}
.shop-modal .modal-title.h4 {
    text-align: center;
    color: #fff;
    width: 100%;
}
.tgoffer-grid {
    background: var(--primary-color);
    color: #fff;
    display: flex;
    align-items: center;
    flex-flow: wrap;
    margin: 10px auto 50px;
    border-radius: 20px;
    position: relative;
    width: 100%;
}
.slidershopshdr {
    color: #212121;
    margin-top: 20px;
}
.slsligrid {
    margin: 0 0 40px;
}
.home-slidershops .swiper-button-next::after,.home-slidershops .swiper-button-prev::after {
    border-radius: 0;
    background: #fff;
    opacity: 1;
    color: #453333;
    border-color: #e6e6e6;
}
.home-slidershops .swiper-button-prev {
    left: 0;
    border-radius: 0;
}
.home-slidershops .swiper-button-next {
    right: 0;
}
.home-slidershops .shop-details {
    padding: 20px 26px;
}
.home-slider.tg-offer-slider {
    max-width: 450px;
    margin-left: 0;
}
.tg-offer-slider .swiper-button-next::after,.tg-offer-slider .swiper-button-prev::after {
    color: #fff;
    font-size: 8px !important;
    width: 22px;
    height: 20px;
}
.tg-offer-slider .swiper-button-next,.tg-offer-slider .swiper-rtl .swiper-button-prev {
    right: 0;
}
.tg-offer-slider .swiper-button-prev,.tg-offer-slider .swiper-rtl .swiper-button-next {
    left: 0;
}
.tgoffer-grid-ryt {
    width: 30%;
    margin-left: auto;
    text-align: center;
}

.tgoffer-grid-lft {position: relative;display: flex;padding: 20px;flex-flow: wrap;width: 70%;align-items: center;}

.tgoffer-hrtxt {
    transform: rotate(-90deg);
    display: block;
    font-weight: 600;
    text-transform: uppercase;
    white-space: nowrap;
    transform-origin: left top 0;
    margin-top: 85px;
    font-size: 10px;
    position: absolute;
    left: 10px;
}
.tgoffer-per {
    width: 75px;
    height: 75px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background: #fff;
    color: #333;
    font-size: 24px;
    font-weight: 600;
    box-shadow: 0px 0px 0px 3px inset var(--primary-color);
    border: 3px solid #fff;
    margin-left: 20px;
}

.tgoffer-desc {
    width: calc(100% - 140px);
    text-align: center;
    display: flex;
    justify-content: center;
    font-size: 20px;
    font-weight: 700;
    padding-left: 15px;
}
.tgoffer-grid:after {
    content: "";
    position: absolute;
    width: 1px;
    height: 100%;
    border-left: 2px dashed #fff;
    right: 30%;
}

.tgoffer-grid-ryt h4 {
    display: inline-block;
    padding: 5px;
    margin: 10px 0;
    box-shadow: 0px 0px 2px 0 rgba(0,0,0,0.3);
    cursor: pointer;
    color: #fff;
    font-size: 20px;
}
.tgoffer-grid-ryt span {
    display: block;
    font-size: 12px;
}
.dropdown-heading {
    font-weight: 600;
    padding: 10px 10px;
    font-size: 18px;
    background: #f2f2f2;
  }
  .postcodeform .dropdown {
    max-height: 300px;
    overflow: hidden;
    overflow-y: auto;
  }
  [class$="postcodeform"] .dropdown {
    max-height: 300px;
    overflow: hidden;
    overflow-y: auto;
  }
  .price-amount .listcloth-rht { 
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: 95px;
  }
  .prodcountinp {
    padding: 0 2px;
  }

.cookies-banner {
    position: fixed;
    bottom: 20px;
    z-index: 999999;
    display: flex;
    justify-content: center;
    margin: 0 auto;
    left: 0;
    right: 0;
    align-items: center;
    max-width: calc(100% - 20px);
}

.cookies-banner-grid {
    background: #fff;
    padding: 30px;
    border-radius: 20px;
    box-shadow: 0 8px 28px rgba(0,0,0,.28);
    max-width: 100%;
}

.cookies-banner-grid-links {
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 15px;
}

.cookies-banner-grid-links button {
    height: 45px;
    min-width: 150px;
    padding: 0 20px;
    border-radius: 35px;
    border: 2px solid;
    font-weight: 600;
}

.cookies-banner-grid-links a {
    text-decoration: underline !important;
    color: #2fa0ea;
    font-weight: 600;
}

.cookies-btn1 {
    background: #2fa0ea;
    color: #fff;
}

.cookies-btn2 {
    background: #fff;
    color: #2fa0ea;
}

.cookiepop-hdr {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 20px;
}

.cookiepop-toggle {
    background: #fff;
    padding: 10px;
    box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.07);
    margin: 0 0 20px;
    border-radius: 2px;
}

.cookiepop-toggle-hdr {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.cookiepop-toggle-hdr button {
    background: transparent;
    border: 0;
}

.cookiepop-toggle-hdr button i {
    margin-right: 10px;
}

.cookiepop-toggle p {
    padding: 20px 0 0;
    border-top: 1px solid #eaeeef;
    margin-top: 20px;
}
.cookiepop-toggle .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }
  
  .cookiepop-toggle .switch input { 
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .cookiepop-toggle .sliders {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .cookiepop-toggle .sliders:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .cookiepop-toggle input:checked + .sliders {
    background-color: #2fa0ea;
  }
  
  .cookiepop-toggle input:focus + .sliders {
    box-shadow: 0 0 1px #2fa0ea;
  }
  
  .cookiepop-toggle input:checked + .sliders:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
  
  /* Rounded sliders */
  .cookiepop-toggle .sliders.round {
    border-radius: 34px;
  }
  
  .cookiepop-toggle .sliders.round:before {
    border-radius: 50%;
  }
#app_cookie_popup {
    background: rgba(0, 0, 0, 0.7);
}
.shop-serv-link .btn {
    font-weight: normal;
    color: #fff;
    padding: 5px 10px;
}
.shop-serv-link .btn:hover {
    color: #fff;
    opacity: 0.9;
}
.shop-serv-link .btn i {
    margin-left: 10px;
}

.shop-serv-link .btn1 {
    background: var(--primary-color);
}

.shop-serv-link .btn2 {
    background: var(--secondary-color);
}

.shop-serv-link {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 2px;
    margin-top: auto;
}
.sidebarmenu {
    position: relative;
    width: 20px;
    height: 2px;
    background: #333;
}

.sidebarmenu:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    background: #333;
    top: -6px;
}

.sidebarmenu:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: -6px;
    background: #333;
}

.more-items {
    position: relative;
    width: 5px;
    height: 5px;
    display: inline-block;
    background: #333;
    border-radius: 50%;
}

.more-items:after {
    content: "";
    position: absolute;
    width: 5px;
    height: 5px;
    background: #333;
    top: -8px;
    border-radius: 50%;
}

.more-items:before {
    content: "";
    position: absolute;
    width: 5px;
    height: 5px;
    background: #333;
    border-radius: 50%;
    bottom: -8px;
}
.prodlistswipe-buttons {
    display: flex;
    justify-content: space-between;
    position: absolute;
    top: calc(50% - 15px);
    width: 100%;
    z-index: 4;
}

.prodlistswipe {
    position: relative;
}

.prodlistswipe-buttons button {
    background: transparent;
    border: 1px solid var(--secondary-color);
    border-radius: 50%;
    color: var(--secondary-color);
    width: 30px;
    height: 30px;
}
.policy-section * {
    max-width: 100%;
}
#sidebar ul li a {
    cursor: pointer;
}
.btnmoblist {
    padding: 10px;
}
#sidebar ul .btnmoblist .btn {
    background: var(--primary-color);
    color: #fff;
}
.offer-section-hdr {
    background: #FF9126;
    text-align: center;
    color: #fff;
    padding: 10px;
}  
.offer-section-hdr .swiper-button-next::after,.offer-section-hdr .swiper-button-prev::after {
    color: #fff;
    font-size: 8px !important;
    width: 20px;
    height: 20px;
}
.social-login .btn {
    width: 50%;
}
.strrating {
    color: #FFAA00;
    margin-right: 10px;
}
.strrating-grid {
    display: flex;
    justify-content: space-between;
    margin: 0 0 10px;
}
.overall-rating {
    display: flex;
    align-items: center;
    color: #FFAA00;
    gap: 10px;
}

.overall-rating span {
    color: #333;
}

.overall-rating h2 {
    font-size: 54px;
}

.section-reviews {
    padding: 40px 0;
}

.rplist li {
    border-bottom: 1px solid #cdcdcd;
    padding: 10px 0;
    margin: 0 0 20px;
}

.rpstar {
    color: #FFAA00;
    display: flex;
    gap: 5px;
    font-size: 18px;
}

.rpdays small {
    color: #acb1d0;
}

.rpgrid strong {
    font-size: 20px;
}
.overall-rating {
    display: flex;
    align-items: center;
    color: #FFAA00;
    gap: 10px;
}

.overall-rating span {
    color: #333;
}

.overall-rating h2 {
    font-size: 54px;
}

.section-reviews {
    padding: 40px 0;
}

.rplist li {
    border-bottom: 1px solid #cdcdcd;
    padding: 10px 0;
    margin: 0 0 20px;
}

.rpstar {
    color: #FFAA00;
    display: flex;
    gap: 5px;
    font-size: 18px;
}

.rpdays small {
    color: #acb1d0;
}

.rpgrid strong {
    font-size: 20px;
}

.overall-rating-container {
    display: flex;
    gap: 20px;
    justify-content: space-between;
    margin: 0 0 20px;
}

.rating-status-container li {
    display: flex;
    align-items: center;
}

.rating-status-grid {
    width: 250px;
    background: #dedede;
    display: flex;
    margin: 0 15px;
    border-radius: 25px;
    height: 4px;
}

.rating-status-count {
    color: #878787;
    font-size: 13px;
}

.rating-status-container i {
    font-size: 9px;
    margin-left: 5px;
}
.rvgrid {
    margin: 20px 0;
    text-align: center;
}

.btnreview.btn.btn-primary {background: transparent;border: 0;color: #333;text-align: center;margin: 0 0 20px;}

.rvsflex {
    display: flex;
    justify-content: center;
    gap: 10px;
    font-size: 30px;
}

.rvsflextext {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.rvsflextext label {
    white-space: nowrap;
}
.notedc {
    font-size: 20px;
    color: #fff;
    margin: 40px 0 0;
  }
@media only screen and (max-width: 767px) {
    .prodlistswipe .swiper-button-next,.prodlistswipe .swiper-button-prev {
        display: none;
    }
}
@media only screen and (max-width:575px){ 
    .cookies-banner-grid-links button {
        min-width: auto;
    }
    .cookies-banner-grid-links {
        gap: 5px;
    }
    .cookies-banner-grid {
        padding: 10px;
    }
    .offermodal-body h3{ font-size:30px; padding:0 0 15px;}
    .offermodal-body h4{ font-size:18px;}
    .offermodal-body p{ font-size:14px; line-height:20px;}
    .offermodal-body .offercode{ font-size:30px; height:55px;}
    .offermodal-body .offercode span{ padding:2px 30px;}
    .offermodal-body .offercode:before, .offermodal-body .offercode:after{ height:55px;}
    .tgoffer-desc {
        font-size: 16px;
    }
    .tgoffer-grid-lft {
        padding: 10px;
    }
    .tgoffer-grid-ryt h4 {
        font-size: 14px;
    }
}