@import 'https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800';
body {
    font-family: open sans,sans-serif;
    font-size: 14px;
    color: #212121;
    font-weight: 400;
    font-style: normal;
}
img {
    max-width: 100%;
}
.sh5-header {
    padding: 10px 0px;
    -webkit-transition: all 0.5s ease;
    -moz-transition: position 10s;
    -ms-transition: position 10s;
    -o-transition: position 10s;
    transition: all 0.5s ease;
}
.sh5-header-nav {
    padding: 0px;
}
.sh5-logo {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: space-between;
    position: relative;
}
#site_logo {
    max-height: 70px;
    border-radius: 5px;
  }
ul.sh5-header-link {
    display: flex;
    height: 100%;
    align-items: center;
}
ul.sh5-header-link li {
    display: inline-block;
    padding: 10px 0px 10px 20px;
}
.sh5-header-link li a {
    font-size: 18px;
    font-weight: 600;
    color: var(--nav-color);
    text-decoration: none;
    font-family: open sans,sans-serif;
    text-decoration: none;
    transition: .5s all;
    -webkit-transition: .5s all;
    -moz-transition: .5s all;
    -o-transition: .5s all;
    -ms-transition: .5s all;
    display: block;
}
.sh5-header-link li .nav-bar-link.active {
    color: var(--nav-color-active);
  }

.sh5-header-link li a:hover {
    color: var(--nav-color-hover);
    text-decoration: none;
}
.sh5-login-m {
    border: 2px solid var(--secondary-color);
    padding: 5px 10px;
    border-radius: 3px;
    background: var(--secondary-color);
}
.sh5-header-link .sh5-signup-m {
    background: var(--secondary-color);
    padding: 5px 10px;
    border-radius: 3px;
    border: 2px solid var(--secondary-color);
    color: #fff;
}
.sh5-header-link .sh5-signup-m:hover {
    background: #fff;
}
ul.sh5-header-link li.login a {
    background-color: var(--secondary-color);
    padding: 8px 28px 8px 28px;
    border-radius: 22px;
    color: #fff;
}
.sh5-header-link li .sh5-cart-btn {
    align-items: center;
    display: inline-flex;
    padding: 0;
    transition: 250ms;
    color: #fff;
    border-radius: 50px;
    width: 48px;
    justify-content: center;
    height: 48px;
    position: relative;
}
.sh5-cart-list .badge {
    background-color: var(--secondary-color);
    position: absolute;
    right: 0;
    top: 0;
    font-size: 12px;
    color: #fff;
}
.sh5-home_single_search {
    width: 100%;
    /* background: url("../../../assets/img/home_page_single_search.jpg") no-repeat top center; */
    position: relative;
    z-index: 1;
}
.sh5-home_single_search::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(65,64,86,.9);
    z-index: -1;
}
.sh5-banner-search-wrap {
    padding: 65px 0;
  }
.sh5-home_single_search h1 {
    font-family: Gilroy, sans-serif;
    color: #fff;
    font-size: 46px;
    font-weight: 600;
    margin-top: 0;
    margin-bottom: 30px;
}
.sh5-home_single_search p {
    display: inline-block;
    color: #fff;
    font-size: 21px;
    font-weight: 500;
}
.sh5-home_single_search .sh5-postcodeform {
    border-radius: 5px;
    display: flex;
    gap: 20px;
    margin-bottom: 0;
    width: 100%;
}
.sh5-home_single_search .sh5-postcodeform input[type=text] {
    border: 1px #000;
    border-radius: 5px;
    box-shadow: 0 4px 4px rgba(0,0,0,.4);
    font-size: 16px;
    height: auto;
    margin-bottom: 0;
    padding: 15px;
    width: 100%;
}
.sh5-home_single_search .sh5-postcodeform-container {
    background: hsla(0,0%,100%,.2);
    padding: 20px;
    position: relative;
  }
.sh5-home_single_search .sh5-postcodeform input[type=submit], .sh5-home_single_search .sh5-postcodeform .btn {
    border-radius: 0;
    padding: 25px;
    font-size: 24px;
    color: #fff;
    border: 0;
    background-color: var(--primary-color);
    flex: 0 0 auto;
    font-weight: 900;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
}
.sh5-home_single_search .sh5-postcodeform .btn a{
    text-decoration: none;
    color: #fff;
}
.sh5-section-title {
    text-align: center;
}
.wash-fold-section {
    background: var(--primary-color) url("../../../assets/img/wash-fold-bg.png");
    padding: 60px 0;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    position: relative; 
    overflow: hidden;
}
.wash-fold-section:before {
    content: "";
    position: absolute;
    background: var(--secondary-color);
    width: 150px;
    height: 150px;
    border-radius: 25px;
    transform: rotate(45deg);
    left: -125px;
    top: 0;
  }
  .wash-fold-section:after {
    position: absolute;
    content: "";
    background: var(--secondary-color);
    width: 150px;
    height: 150px;
    border-radius: 25px;
    bottom: 24px;
    right: -90px;
    transform: rotate(26deg);
  }
.wash-fold-card {
    display: flex;
    background-color: #fff;
    padding: 30px;
    border-radius: 10px;
    align-items: center;
    position: relative;
    z-index: 1;
}
.wash-fold-text {
    background: var(--secondary-color);
    border-radius: 20px 10px 10px 20px;
    padding: 40px 25px;
    margin-right: 30px;
    display: flex;
    align-items: center;
}
.wash-fold-text h2 {
    color: #fff;
    text-align: center;
    text-transform: uppercase;
    font-size: 30px;
    font-weight: 800;
    line-height: 35px;
}
.wash-fold-info ul {
    list-style: none;
    padding: 0;
    margin: 0;
}
.wash-fold-info ul li {
    display: flex;
    align-items: baseline;
}
.wash-fold-info li i {
    font-size: 18px;
    color: var(--secondary-color);
    margin-right: 15px;
}
.easelaundry-content-left {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
}
.wash-fold-info ul li p {
    font-size: 20px;
    color: #222;
    font-weight: 600;
    margin-bottom: 17px;
    line-height: 25px;
}
.section-title h2::after {
    display: none;
}
.aboutsec {
    padding: 60px 0;
    color: #fff;
    background-repeat: no-repeat;
    background-size: cover;
}
.section-title {
    text-align: center;
}
.aboutsec .section-title-h2 {
    margin-bottom: 20px;
    color: #fff;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 22px;
}
.aboutsec .section-title .section-title-head {
    color: #fff;
    margin-bottom: 20px;
    text-align: left;
}
.aboutsec .section-line span {
    margin-left: 54px;
}

.abt-cont-desc {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  .abt-cont-desc p {
    color: #363636;
  }
  .abt-cont-desc h3 {
    color: #656565;
    font-size: 36px;
    font-weight: 700;
    margin: 15px 0 35px;
  }
  .abt-cont-desc h4 {
    color: var(--secondary-color);
    font-size: 23px;
    font-weight: 600;
  }
  .abt-cont-img {
    margin: 50px 0;
  }
  
.about-step-desc {
    text-align: center;
    box-shadow: 0px 10px 10px #F5F5F5;
    border-radius: 10px;
    border: 1px solid rgba(56, 162, 232, 0.1);
    background: #fff;
    padding: 30px 15px;
    display: flex;
    height: 100%;
  }
  .about-step-desc p {
    color: #353535;
    font-size: 14px;
    margin: 0;
  }
  .about-step-desc h4 {
    margin: 20px 0 10px;
    font-size: 24px;
  }
  .about-footer-sec {
    background: #fef6f6;
    margin: 75px auto;
  }
.section-line span {
    display: block;
    height: 2px;
    width: 13px;
    background: transparent;
    margin: 6px auto 0;
    position: relative;
    border-radius: 30px;
}
.section-line .first-line::before {
    content: '';
    position: absolute;
    right: 13px;
    top: 0;
    height: 2px;
    width: 50px;
    border-radius: 17px;
    background: #fff;
}
.section-line .first-line::after {
    content: '';
    position: absolute;
    left: 13px;
    top: 0;
    height: 2px;
    width: 50px;
    border-radius: 17px;
    background: #fff;
}
.section-line.small-line span {
    width: 11px;
}
.aboutsec .section-line span {
    margin-left: 54px;
}
.section-line.small-line .second-line::before {
    right: 11px;
    top: 0;
    height: 2px;
    width: 26px;
}
.section-line.small-line .second-line::after {
    left: 11px;
    top: 0;
    height: 2px;
    width: 26px;
}
.aboutsec p {
    margin: 20px 0 25px;
    font-size: 16px;
}
.about-icon {
    margin-top: 35px;
}
.about-icon .media {
    margin-bottom: 18px;
    align-items: flex-start;
    display: flex;
}
.media:first-child {
    margin-top: 0;
}
.about-icon .media-body h5 {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 10px;
    color: #fff;
}
.about-icon .media-body p {
    font-size: 15px;
    line-height: 22px;
}
.aboutsec p {
    margin: 20px 0 25px;
    word-break: break-word;
}
.about-icon .media img {
    width: 80px;
    margin-right: 15px;
}

.easelaundry-wrapper {
    padding: 60px 0;
}
.easelaundry-wrapper img {
    border-radius: 15px;
}
.aboutsec h1, .easelaundry-wrapper h1 {
    margin: 0px 0px 14px 0px;
}
.section-app {
    padding: 50px 0px;
}
.app-download-blk {
    background: #fff;
}
.app-content h2 {
    margin-top: 0px;
}
.app-content h2 {
    font-size: 32px;
    font-weight: 700;
    color: var(--primary-color);
}
.app-content p {
    font-size: 24px;
    margin-top: 20px;
    color: #000;
    line-height: 30px;
    word-break: break-all;
}
.app-download-blk .input-group {
    border: 1px solid #CDCDCD;
    border-radius: 10px !important;
    box-shadow: inherit;
    min-height: 84px;
    padding: 10px;
    margin-bottom: 0;
    font-weight: bold;
    font-size: 14px;
    background-color: #fff;
    margin-bottom: 30px;
}
.sh5-footerlist-two ul li a {
    display: block;
    color: var(--footer-paragraph);
    padding-right: 0;
    line-height: 24px;
    font-size: 16px;
    padding-bottom: 5px;
    text-decoration: none;
}
.app-download-blk .input-group .form-control {
    border: none;
    height: 68px;
    line-height: 50px;
    border-radius: 0;
    box-shadow: none;
    font-weight: bold;
}
.app-download-blk .custom-style-btn {
    border-radius: 7px;
}
.app-download-blk .custom-style-btn button {
    background: var(--secondary-color);
    border: none;
    border-radius: 7px;
    color: #fff;
    cursor: pointer;
    font-size: 30px;
    height: 68px;
    line-height: 50px;
    width: 100px;
}
.app-content ul {
    padding: 0;
}
.app-content ul li:first-child {
    padding-right: 15px;
}
.app-content ul li {
    display: inline-block;
}

.sh5-footer {
    background-color: #681986;
    background-color: var(--primary-color);
    padding-top: 40px;
}
.sh5-footer-one {
    padding-bottom: 20px;
}
.sh5-home-links ul {
    border-bottom: 1px solid #fff;
    margin: 0;
    padding: 20px 0;
}
.sh5-home-links ul li {
    display: inline-block;
    padding-right: 15px;
}
.sh5-home-links ul li a {
    color: #fff;
    font-size: 16px;
    font-weight: 700;
    text-decoration: none;
}
.sh5-footer-three {
    margin-top: 30px;
}
.sh5-footerlist-two h4 {
    color: var(--footer-header);
    font-size: 20px;
    font-weight: 900;
    margin-bottom: 30px!important;
    position: relative;
    text-transform: uppercase;
}
.sh5-footerlist-two h4:before {
    background: var(--footer-header);
    border-radius: 10px;
    bottom: -10px;
    content: "";
    height: 4px;
    left: 0;
    position: absolute;
    width: 60px;
}
.sh5-footerlist-two ul {
    margin: 0;
    padding: 0;
}
.sh5-footerlist-two ul li {
    color: #fff;
    display: block;
    font-size: 16px;
    line-height: 24px;
    padding-bottom: 5px;
    padding-right: 0;
}
.sh5-app-icons>ul {
    display: flex;
}
.sh5-footerlist-two ul {
    margin: 0;
    padding: 0;
}
p.sh5-footer-last {
    color: #fff;
    float: left;
    font-size: 14px;
    font-weight: 600;
    margin: 0 0 10px;
    padding-bottom: 15px;
    padding-top: 15px;
}
.sh5-footer-last a {
    color: var(--footer-paragraph);
    text-decoration: none;
}
.sh5-footer-social {
    margin-top: 10px;
}
.sh5-footer-social {
    margin-top: 10px;
}
.sh5-footer-social ul li {
    display: inline-block;
    padding-right: 5px;
}
 .sh5-footer-social ul li a {
    padding-right: 0;
}
.sh5-postcodeform .dropdown{
    position: absolute;
    margin-top: 54px;
    background: #fff;
    width: 29rem;
    box-shadow: 0px 2px 3px rgba(0,0,0,0.2);
    border-radius: 5px;
    z-index: 99;
}  
.sh5-footer a {
    color: #fff;
}
@media only screen and (max-width:991px){ 
    ul.sh5-header-link li {
        padding: 10px 0px 10px 5px;
    }
}
@media only screen and (max-width: 767px) { 
  .sh5-home_single_search h1 {
    font-size: 24px;
  }
  .sh5-postcodeform {
    flex-direction: column;
  }
  .sh5-home_single_search {
    overflow: visible;
    padding: 40px 0;
  }
  .sh5-logo #site_logo {
    max-height: 30px;
    border-radius: 0;
  }
  .sh5-logo #sidebarCollapse img,.sh5-logo .mob-user img {
    filter: brightness(1) invert(0);
  }
  .sh5-postcodeform-container .adrsselectdd.adrsselectddinp {
    flex-direction: column;
  }
  .sh5-safety-section {
    padding: 40px 0;
  }
  .sh5-testimonial-section,.sh5-cate-section {
    padding-bottom: 0;
    padding-top: 20px;
  }
  .sh5-mobapp-section,.sh5-footer,.sh5-serv-section {
    padding-top: 20px;
  }
  .sh5-safety-list-card {
    margin-top: 15px;
    margin-bottom: 5px;
  }
  .sh5-safety-content {
    margin-top: 20px;
  }
  .sh5-howwe-section,.sh5-review-section {
    padding: 20px 0;
  }
  .sh5-banner-search-wrap {
    padding: 0;
  }
  .sh5-home_single_search .sh5-postcodeform input[type=submit],
   .sh5-home_single_search .sh5-postcodeform .btn {
    padding: 10px;
  }
  .easelaundry-wrapper {
    padding: 20px 0;
  }
  .section-app {
    padding: 0 0 20px;
  }
}
@media only screen and (max-width:575px){ 

}
@media only screen and (max-width:479px){ 

}